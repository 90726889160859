/*-- Import supporting css*/
@import "./config.scss";
@import "./components/selectBox.module.scss";
@import "./components/dataTable.module.scss";
@import "./components/pie.module.scss";
@import "./components/bar.module.scss";
@import "./components/area.module.scss";
@import "./components/stacked.module.scss";
@import "./components/dateRangepicker.module.scss";
@import "./components/risk.module.scss";
@import "./components/fundClass.module.scss";
@import "./components/radio.module.scss";
@import "./components/accordion.module.scss";

/*---------- Body ---------- */
body {
  font-family: $font-primary;
  background-color: $body_bg;
  -webkit-font-smoothing: antialiased;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0cbec !important;
    padding: 10px;
    // border-radius: 10px;
    // border-radius: 10px;
  }
}

label {
  @extend %font12;
  font-weight: 400;
}

a {
  text-decoration: none;
}

/*------- App layout --------*/
.e-content-wrap {
  min-height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.e-sidebar-wrap {
  transition: ease-in-out 0.5s;
  position: relative;
  height: 100vh;
  width: 307px;
}

.e-sidebar-collapsed {
  transition: ease-in-out 0.5s;
  width: 50px;
}

.e-content-body {
  margin-top: 0.5%;
}
.border-bottom {
  border-bottom: solid 0.5px $light_grey;
}

.e-w-1600 {
  min-width: 1600px;
}
.e-w-800 {
  min-width: 600px;
}

/*----auth--------*/
.e-auth-wrapper {
  background-color: $white;

  .e-form-wrap {
    background: $white;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 25px;

    h4 {
      @extend %h4;
      line-height: 33px;
      font-family: $font-primary;
      color: $black;
      font-weight: 600;
    }

    p {
      font-weight: 400;
      @extend %font14;

      span {
        font-weight: 600;
      }
    }
  }

  .e-disabled {
    opacity: 0.5;
    pointer-events: none;
    font-weight: 700;
    @extend %font14;
  }
}

.h-100vh {
  height: 100vh;
}

.w-250px {
  width: 250px;
}

.e-cart-input {
  background: #e7eded;
  border: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: inline-block;
  padding: 7px 70px 7px 42px !important;
  background-image: url(../assets/images/cta/search.svg);
  background-size: 14px;
  background-position: 20px 11px;
  background-repeat: no-repeat;
  font-weight: 500;
  @extend %font14;
  color: $black;
  &::placeholder {
    font-weight: 400;
    @extend %font12;
    line-height: 17px;
    letter-spacing: -0.01em;
    color: $light_grey;
    opacity: 0.8;
  }
}

.font-weight-600 {
  font-weight: 600;
}

.e-auth-footer {
  margin-top: 100px;
}

.e-disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 700;
  @extend %font14;
}

/*------ Dashboard ------ */
.e-card-setcion-title,
.e-section-title {
  @extend %font14;
  line-height: 130%;
  letter-spacing: 0.007em;
  color: $grey;
}
.e-card-setcion-title {
  font-weight: 600;
}
.e-section-title {
  font-weight: 600;
}
.e-card-title {
  @extend %h6;
  font-weight: 600 !important;
  color: $card_title !important;
  @extend %font14;
}
.e-btn-active-wrapper {
  height: 39px;
  background: $primary_color;
  border-radius: 8px;
  padding: 8px 10px;
  margin-right: 12px;
  text-align: center;
  cursor: pointer;

  .e-btn-active {
    font-weight: 500;
    @extend %font14;
    line-height: 24px;
    color: $white;
  }
}
.e-equity {
  font-size: 14px;
  line-height: 21px;
  color: #2d2d2d;
  h6 {
    font-weight: 600 !important;
  }
  span {
    font-weight: 700;
  }
}

.e-btn-wrapper {
  height: 39px;
  background: $white;
  border-radius: 4px;
  padding: 6px 10px;
  margin-right: 12px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  cursor: pointer;
  // filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));

  &:hover {
    transform: scale(1.02);
  }

  .e-btn-active {
    font-weight: 500;
    @extend %font14;
    line-height: 24px;
    color: $light_grey;
  }
}

.e-dashboard-card {
  border-radius: 16px;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  background: $white;
  padding: 12px;
  height: 100%;

  .e-dashboard-card-alloc {
    border-radius: 8px;
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
    background: $white;
    padding: 12px;

    span {
      @extend %font12;
      color: $light_grey;
      font-weight: 600;
    }
  }

  .e-btn-active-wrapper {
    width: 45px;
    height: 39px;
    background: $primary_color;
    border-radius: 4px;
    padding: 8px 10px;
    margin-right: 12px;
    text-align: center;
    cursor: pointer;

    .e-btn-active {
      font-weight: 500;
      @extend %font14;
      line-height: 24px;
      color: $white;
    }
  }

  .e-btn-wrapper {
    width: 45px;
    height: 39px;
    background: $white;
    border-radius: 4px;
    padding: 8px 10px;
    margin-right: 12px;
    text-align: center;
    cursor: pointer;
    filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.16));

    &:hover {
      transform: scale(1.02);
    }

    .e-btn-active {
      font-weight: 500;
      @extend %font14;
      line-height: 24px;
      color: $light_grey;
    }
  }

  .css-26l3qy-menu,
  .css-4ljt47-MenuList {
    @extend %font12;
  }

  .e-radial {
    .apexcharts-canvas {
      left: -129px;
      top: -20px;
    }
  }

  .e-s-btn {
    // width: 86px;
    .secondary_e_s_btn__16DLu {
      padding: 10px;
    }
  }

  .e-piechart-label {
    top: 20px;
    right: 15%;

    h2 {
      color: $dark_grey;
      font-weight: 600;
      @extend %font24;
      line-height: 29px;
      margin-block-end: 6px;

      span {
        @extend %font18;
      }
    }

    p {
      color: $dark_grey;
      font-weight: 500;
      @extend %font14;
      line-height: 17px;
    }
  }
}

.e-bar-scroll {
  position: relative;
  overflow-x: scroll;
  width: 100%;
}

.e-bar-scroll::-webkit-scrollbar-button {
  display: none;
}

.e-bar-scroll::-webkit-scrollbar {
  width: 16px;
  height: 12px;
  padding: 20px;
  border-radius: 16px;
}

.e-bar-scroll::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 16px;
  margin: 20px;
  border-bottom: 3px solid white;
}

.e-bar-scroll::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 16px;
  border: 0px solid #fff;
  border-bottom: 3px solid white;
}

.bounce {
  position: relative;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust bounce height */
  }
}
.e-table-scroll {
  min-width: 1200px;
}
.e-order-scroll {
  overflow-x: scroll;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0cbec !important;
    padding: 10px;
    // border-radius: 10px;
    // border-radius: 10px;
  }
}
.e-round-chart-container {
  height: 420px;
}

.e-dashboard-row-2 {
  height: 485px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #b0cbec !important;
    padding: 10px;
    // border-radius: 10px;
    // border-radius: 10px;
  }
}

/*------ App notification ------*/
.e-notification .tab_e_active_tab__3jjiq {
  background-color: transparent !important;
  color: $black;
  border-bottom: 3px solid $primary_color;
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 6px 0px;
  font-weight: 600;
  @extend %font14;
  line-height: 17px;
}

.e-notification-form-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.e-notification .e-disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 700;
  @extend %font14;
}

.e-notification .tab_e_tab__3yoyr {
  color: $black;
  border: none;
  padding: 6px 0px;
  border-radius: 0px;
  font-weight: 600;
  @extend %font14;
  line-height: 17px;
  border-bottom: 3px solid transparent;

  &:hover {
    background-color: transparent;
  }
}

.e-notification-form-element {
  &::placeholder {
    color: $dark_grey;
    opacity: 0.27;
  }
}

.e-notification-form-btn {
  @extend %font14;
}

.e-offer-body {
  height: 120px;
}

.e-sip-image {
  margin: 20px 0px;
  min-width: 42px;
  min-height: 42px;
  border-radius: 100%;
  object-fit: cover;
}

.e-notification-history {
  background-color: $accordion_bg;
  border-radius: 8px;

  .e-notification-history-time {
    color: $light_grey;
    line-height: 15px;
    @extend %font12;
    font-weight: 400;
  }

  .e-notification-history-content {
    color: $light_grey;
    line-height: 20px;
    @extend %font14;
    font-weight: 400;
  }

  .e-title {
    color: $dark_grey;
    font-weight: 600;
    line-height: 20px;
    @extend %font14;
  }
}

.e-notification-sec {
  background: #edf3ff;
  border-radius: 8px;
  cursor: pointer;

  p {
    @extend %font14;
    line-height: 20px;
    color: $light_grey;
    font-weight: 400;
  }
}
.e-notification {
  // height: 110vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .ck-editor {
    padding: 8px !important;
    border: 0.5px solid #d2d2d2 !important;
    border-radius: 8px !important;
  }
  .ck.ck-editor__main > .ck-editor__editable {
    height: 150px !important;
    border-bottom: none !important;
  }
}

.e-notification-sub-sec {
  background-color: $white;
  cursor: pointer;

  h4 {
    @extend %font14;
    line-height: 20px;
    color: $dark_grey !important;
    font-weight: 400 !important;
  }

  p {
    @extend %font14;
    line-height: 20px;
    color: $light_grey;
    font-weight: 400;
  }
}

/*----- Manage Content ------*/
.e-add-faq-btn {
  @extend %font14;
  width: 90%;
  padding: 14px;
}

/* wysiwig editor */
.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  padding-bottom: 300px;

  border-radius: 0px 0px 80px 8px;
  background-color: $white;
  margin-top: 20px;
  // background-color: $white;
  p {
    color: $black !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 25px;
    margin-bottom: 0;
  }
  p:nth-of-type(1) {
    margin-bottom: 20px;
  }
  h6 {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}
.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  border: none !important;
  box-shadow: none !important;
}
.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border: none;
  padding: 12px 12px 12px 0;
  background-color: $white !important;
  border-bottom: 1.6px solid #bdbdbd !important;
}
.ck.ck-dropdown {
  &[aria-labelledby="ck-editor__aria-label_e74d295f4749ee2c9e4e0304f597225aa"] {
    display: none !important;
  }
}
.ck .ck-button {
  &[aria-disabled="true"] {
    display: none !important;
  }
}
.ck-toolbar__items {
  .ck-dropdown:nth-of-type(1) {
    display: none !important;
  }
  .ck-dropdown:nth-of-type(3) {
    display: none !important;
  }
  .ck-dropdown:nth-of-type(2) {
    display: none !important;
  }
}
.ck-sticky-panel__content {
  z-index: 2 !important;
}
.ck-toolbar {
  &[aria-label="Dropdown toolbar"] {
    border-radius: 0px !important;
  }
}
.ck.ck-toolbar .ck.ck-toolbar__separator {
  display: none !important;
}
.ck-file-dialog-button {
  // display: none!important;
}
.ck .ck-button[aria-labelledby="ck-editor__aria-label_eca6d855d9e456b2b02e398f738ab9740"] {
  // display: none!important;
}
.ck .ck-button[aria-labelledby="ck-editor__aria-label_e26a6ab70fcce2fc537813a2e58106fc7"] {
  // display: none!important;
}
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  border-color: none !important;
}
.ck.ck-editor__main > .ck-editor__editable {
  padding: 0 100px 0 28px !important;
  border-bottom: 1.6px solid #bdbdbd !important;
  height: 680px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 10px !important;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #dcdbdb;
    height: 10px !important;
    // border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    // height: 10px !important;
  }
}
.ck.ck-editor__editable_inline {
  border: none !important;
}
.ck .ck-button[aria-labelledby="ck-editor__aria-label_edd9fb7b17c77d13ecab5376a0a4cda51"] {
  display: none !important;
}
.ck.ck-toolbar > .ck-toolbar__items > :not(.ck-toolbar__line-break) {
  margin-right: 30px !important;
}
.e-manage-content {
  .e-updated-time {
    font-weight: 500;
    color: #9ea3aa;
    font-size: 14px;
    line-height: 24px;
  }
  h4 {
    font-weight: 400;
    @extend %font48;
    line-height: 120%;
    letter-spacing: -2.50105px;
    color: #11142f;
  }
  h6 {
    @extend %font18;
  }
}

/*--------------------------------*/
.e-accordion-editor {
  .ck.ck-editor {
    border: 1.5px solid #7d7688 !important;
    border-radius: 8px;
    padding: 0px 10px !important;
  }

  .ck.ck-editor__main > .ck-editor__editable {
    padding: 0 10px !important;
    border-bottom: none !important;
    height: 200px;
  }
  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border: none;
    background-color: $white !important;
    border-bottom: 1.6px solid #bdbdbd !important;
  }
}

.e-tab-position {
  margin-top: -6% !important;
  position: absolute;
  h3 {
    color: $white !important;
    @extend %font24;
    font-weight: 600;
  }
  .e-active-tab {
    color: $white !important;
    @extend %font24;
    font-weight: 600;
    &::before,
    &::after {
      background-color: transparent !important;
    }
  }
  .e-tab {
    color: $tab_color;
    @extend %font24;
    font-weight: 600;
    &::before {
      background-color: $white !important;
      height: 1px !important;
    }
  }
}

.e-faq-card {
  min-height: 600px;

  h6 {
    color: $black !important;
    font-weight: 700;
  }
}

.e-cancel {
  width: 98px;
  height: 40px;
  padding: 0 !important;
  margin-right: 14px;
}

.e-modal-submit {
  font-weight: 600 !important;
  padding: 0 !important;
  @extend %font14;
  width: 155px;
  height: 40px;
}

.e-filter-btn {
  width: 36px;
  height: 36px;
}

.e-error {
  @extend %font12;
  color: $error !important;
}

.e-breadcrumb-link {
  font-weight: 300;
  @extend %font16;
  line-height: 24px;
  color: $black;
  position: relative;
  // top: -100%;

  a {
    text-decoration: none;
    color: $black;
    padding-right: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.e-nodata {
  width: 256px;
  height: 202px;
  margin: auto;
}

/* ------ banner --------*/
.e-border {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 0.2px;
    width: 100%;
    bottom: 1px;
    background-color: #dbdbdb;
  }
}
.e-uploader {
  border: 2px dashed #dbdbdb;
  border-radius: 8px;
  padding: 36px 0px;
  cursor: pointer;
  p {
    color: $light_grey;
    font-weight: 400;
    font-size: 14px;
    span {
      color: #bebebe;
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
    }
  }
}
.e-preview {
  height: 106px;
  width: 100%;
  border: 2px dashed #dbdbdb;
  border-radius: 8px;
  padding: 8px;
  // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  .e-choose-btn {
    background: $white;
    border-radius: 38px;
    color: $grey;
    font-weight: 400;
    line-height: 130%;
    @extend %font14;
    position: absolute;
    padding: 4px 12px 4px 6px;
    width: max-content;
    height: max-content;
    // left: 32%;
    // top: 38%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
  }
  .e-drag-text {
    position: absolute;
    color: $white;
    left: 10%;
    bottom: 10%;
  }
  .e-delete-image {
    right: 16px;
    top: 16px;
    cursor: pointer;
  }
}
.e-delete {
  border: 1px solid $red !important;
  padding: 8px 16px;
  border-radius: 8px;
  color: $red;
  font-weight: 600;
  @extend %font14;
  line-height: 24px;
}

.e-link-delete {
  color: $red;
}
.e-type-sec {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    height: 0.2px;
    width: 100%;
    bottom: 1px;
    background-color: #dbdbdb;
  }
  .e-tab {
    @extend %font14;
    font-weight: 600;
    line-height: 120%;
    color: $tab_color;
    transition: ease 0.3s;
    padding-bottom: 5px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 3px;
      background-color: $primary_color;
      bottom: 0;
      transition: all 0.35s;
    }
    &:hover {
      &:before {
        width: 100%;
      }
      cursor: pointer;
      opacity: 1;
    }
  }
  .e-active-tab {
    @extend .e-tab;
    transition: ease 0.3s;
    opacity: 1;
    color: $light_grey;
    font-weight: 600;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: $primary_color;
      bottom: 0px;
      left: 0;
    }
  }
}
/*----- Chat Box -------*/
.e-chat-box {
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  height: 884px;

  .e-placeholder {
    width: 48px;
    height: 48px;
    color: $white;
    font-weight: 600;
    @extend %font14;
    line-height: 130%;
  }

  .e-user-name {
    color: $light_grey;
    @extend %font12;
  }

  .e-status-badge-open {
    color: $light_grey;
    @extend %font10;
    border: 1px solid $light_grey;
    box-sizing: border-box;
    border-radius: 100px;
    text-transform: capitalize;
    width: max-content;
    padding: 1px 8px;
  }

  .e-status-badge-pending {
    @extend .e-status-badge-open;
    color: $orange;
    border: 1px solid $orange;
  }

  .e-status-badge-closed {
    @extend .e-status-badge-open;
    color: $primary_color;
    border: 1px solid $primary_color;
  }

  h6 {
    @extend %h6;
    font-weight: 600;
  }

  .e-chat-wrap {
    transition: ease 0.3s;

    &:hover {
      background-color: rgba(185, 245, 163, 0.3);
      cursor: pointer;
    }
  }
}

/*-----Chat container ------*/
.e-chat-container {
  border-radius: 8px;
  height: 884px;

  .e-chat-header {
    box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);

    .e-user-placeholder {
      width: 48px;
      height: 48px;
      font-weight: 600;
      @extend %font14;
      line-height: 130%;
      color: $white;
    }

    h6 {
      color: $dark_grey;
      line-height: 130%;
      font-weight: 600;
      @extend %font18;
    }

    p {
      color: $light_grey;
      font-weight: normal;
      @extend %font14;
      line-height: 17px;
    }

    .e-ticket-label {
      @extend %h6;
    }

    .e-ticket {
      color: $light_grey;
      @extend %font14;
      line-height: 17px;
    }
  }

  .e-chat-placeholder {
    width: 32px;
    height: 32px;
    color: $white;
    @extend %font12;
    font-weight: 600;
    line-height: 130%;
  }

  .e-admin-icon {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }

  .e-chat-content {
    color: $dark_grey;
    @extend %font14;
    line-height: 130%;
  }

  .e-date {
    color: $light_grey;
    line-height: 15px;
    @extend %font12;
  }

  .e-chat-row {
    border-bottom: 1px solid $off_white;
  }

  .e-closed-badge {
    background-color: $light_green;
    border-radius: 100px;
    color: $green;
    line-height: 15px;
    position: absolute;
    left: 37%;
    @extend %font12;

    img {
      width: 13.33px;
      height: 13.33px;
      object-fit: cover;
    }
  }

  .e-message-input {
    // margin-top: 310px;
    .e-input-icon {
      position: absolute;
      width: 30px;
      height: 30px;
      object-fit: cover;
      z-index: 2;
      top: 10px;
      left: 25px;
    }

    .e-sent-btn {
      position: absolute;
      right: 5px;
      z-index: 2;
      width: 50px;
      height: 100%;

      &:hover {
        cursor: pointer;
      }
    }

    .e-chat-input {
      background-color: $off_white;
      border: none;
      position: relative;
      padding: 14px 50px 14px 50px;

      &:placeholder {
        color: $light_grey;
      }
    }
  }

  .e-chatbox-wrper {
    height: 685px;
  }
}

.e-chat-active {
  background-color: red !important;
}

/*------Users-----*/
.e-filter-wraper {
  background: #e7eded;
  padding: 12px 20px;

  label {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #2b4240;
  }
}

.e-filter-btn.e-active {
  border: 1px solid $primary_color;
}

.e-investment-tooltip {
  .e-icon-tooltip {
    top: 40px;
  }
}
.e-profile-holdings {
  padding: 32px;
  .e-icon-tooltip {
    top: 58px;
    left: 26px;
  }
  h4 {
    font-family: $font-primary;
    font-weight: 700;
    @extend %font14;
    color: #2d2d2d;
  }

  p {
    font-family: $font-primary;
    font-weight: 600;
    @extend %font18;
    color: #2d2d2d;
  }
}

.e-profile-graph {
  height: 100%;

  h4 {
    @extend %font14;
    color: $black;
    font-weight: 700;
  }

  p {
    font-family: $font-primary;
    font-weight: 500;
    @extend %font14;
    color: $light_grey;
  }
}

.e-graph-labels {
  max-height: 170px;
  overflow-y: scroll;
}

.e-graph-labels::-webkit-scrollbar {
  height: 0px !important;
  width: 8px;
}

.e-graph-labels::-webkit-scrollbar-thumb {
  background: #edf3ff;
}

.e-horizontal-scroll {
  white-space: nowrap;
  overflow-x: scroll;
}
.e-horizontal-scroll::-webkit-scrollbar {
  height: 8px !important;
  width: 0px;
}

.e-horizontal-scroll::-webkit-scrollbar-thumb {
  background: #edf3ff;
}

.e-order-detail {
  max-height: 420px;
  overflow-y: scroll;
  overflow-x: hidden;

  .e-order-row {
    background: rgba(13, 13, 13, 0.02);
  }
}

.e-order-detail::-webkit-scrollbar {
  height: 0px !important;
  width: 8px;
}

.e-order-detail-modal {
  .modal-content {
    padding: 0px !important;
  }
}

.e-order-detail::-webkit-scrollbar-thumb {
  background: #c4dcf3;
}

.e-icon-tooltip {
  right: 0 !important;
  max-width: 300px;
  top: 84px;
  z-index: 3;
}

.e-current-tooltip {
  top: 20px;
  position: absolute;
  left: 0;
  width: 250px;
}

.e-holding-table {
  .e-deny {
    padding: 12px 24px !important;
  }

  h4 {
    @extend %font14;
    color: $black;
    font-weight: 700;
  }

  .e-profile-active-btn {
    background: $primary_color;
    border-radius: 4px;
    color: $notification_boder;
    @extend %font14;
    padding: 4px 8px;
    width: 100%;
    margin-left: 10px;
    cursor: pointer;
  }

  .e-profile-btn {
    background: $notification_boder;
    border-radius: 4px;
    color: $black;
    @extend %font14;
    padding: 4px 8px;
    width: 100%;
    margin-left: 10px;
    cursor: pointer;
  }

  .e-holding-table-heading {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  }

  .e-holding-table-data {
    background-color: $download_bg;
    padding: 30px;

    .e-table-data {
      font-family: $font-primary;
      font-weight: 500;
      @extend %font14;
      color: $dark_grey;
      line-height: 24px;
    }

    .e-table-data-percent {
      font-family: $font-primary;
      font-weight: 500;
      @extend %font14;
      color: $dark_grey;
      position: relative;
      top: -2px;
      left: 0px;
    }

    .e-current-data-desc {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 500;
      @extend %font14;
      color: #0fd99c;
    }
  }
}

.e-user-request {
  .e-user-request-sec {
    background-color: $download_bg;
    padding: 30px;
  }

  h4 {
    font-family: $font-primary;
    font-weight: 700;
    @extend %font14;
    color: $dark_grey;
  }

  p {
    color: $dark_grey !important;
    @extend %font14;

    .e-bold {
      font-weight: 600;
    }
  }
}

.progress {
  background-color: transparent !important;
  justify-content: start;

  .progress-bar {
    border-radius: 34px;
    margin-top: 5px;
  }

  .progress-bar-international {
    background-color: #6439ec;
    border-radius: 34px;
  }
}

.e-current-title {
  font-family: $font-primary;
  font-weight: 500;
  @extend %font14;
  color: $light_grey;
}

.e-current-value {
  font-family: $font-primary;
  font-weight: 600;
  @extend %font18;
  color: $primary_color !important;
}

/*-- Analytics --*/
.e-analytics-card {
  .tab_e_active_tab__2nkVn {
    background-color: transparent !important;
    padding: 0px;
    border: none !important;
    color: $white !important;
    opacity: 1 !important;
    span {
      // padding: 12px 12px 4px 12px;
      color: $white !important;
      &::after {
        content: "";
        display: block;
        width: 100%;
        position: relative;
        bottom: 0px;
        top: 9px;
        height: 2px;
        background: $primary_color;
        transition: width 0.3s;
        z-index: 1;
      }
    }
  }

  .tab_e_tab__-VrS6 {
    padding: 0px;
    background-color: transparent !important;
    border: none !important;
    color: $white !important;

    span {
      color: $white !important;
      // padding: 12px 12px 0px 12px;
      &:hover {
        opacity: 1;
      }
      &:before {
        content: "";
        position: absolute;
        width: 0px;
        height: 3px;
        bottom: -9px;
        left: 0;
        visibility: visible;
        // color: $primary_color;
        background-color: $primary_color;
        transition: ease-in-out 0.5s;
      }
      &:hover {
        &:before {
          width: 100%;
          height: 3px;
        }
      }
    }
  }
}

.e-drop {
  color: #de2020;
}

.e-funds-list {
  max-height: 350px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 0px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4dcf3 !important;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}
.e-analytics {
  .css-13cymwt-control {
    min-height: 20px !important;
    border-radius: 4px !important;
  }

  .css-t3ipsp-control {
    min-height: 20px !important;
    border-radius: 4px !important;
  }
}

/*---Quiz---*/
.e-quiz-card {
  .e-quiz-scroll {
    overflow-x: scroll;
  }

  .e-quiz-scroll::-webkit-scrollbar {
    display: none;
  }

  .tab_e_tab__3yoyr,
  .tab_e_tab__2sfKQ {
    background-color: $accordion_bg !important;
    border-radius: 8px !important;
    color: $dark_grey;
    padding: 12px 15px !important;
    min-width: 218px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .tab_e_active_tab__3jjiq {
    border-radius: 8px !important;
    padding: 12px 15px !important;
    min-width: 218px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.e-quiz-tab {
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-quiz-icon {
  position: absolute;
  top: 32%;
  right: 0px;
  background-image: url(../assets/images/dataFormating/add.svg);
  background-repeat: no-repeat;
  padding: 13px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.e-add-sec {
  border: 1px solid $accordion_body_bg;
  border-radius: 8px;
  width: 100%;
  padding: 2px 10px 7px;
  margin-bottom: 20px;
  cursor: pointer;

  h6 {
    color: $primary_color !important;
    @extend %font14;
    line-height: 24px;
    font-weight: 700 !important;
    padding: 12px 10px 0px;
    width: 100%;

    span {
      cursor: pointer;
    }

    &:hover {
      translate: scale(1.5);
    }
  }
}

.e-add-quiz {
  font-weight: 700;
  @extend %font14;
  line-height: 24px;
  color: $primary_color;
  padding: 12px 30px;
  border: 1px solid $accordion_bg;
  border-radius: 8px;
  background-color: $accordion_bg;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
}

/* manage funds */
.e-allcation-accordion {
  .fundTab_e_active_tab__2B65q {
    color: $primary_color !important;
  }
}
.e-info-section {
  background: #e8eaf4;
  border-radius: 8px;
  padding: 16px 0;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: $black;
  }
}

/* manage returns */
.e-manage-returns {
  h6 {
    font-weight: 600;
  }
}
/*------ edit score request css---*/
.e-request-card {
  .e-reject-link {
    color: $badge_fail;
  }

  .e-request-status {
    font-weight: 600;
    line-height: 17px;
    @extend %font14;
    color: $primary_color;
    text-align: center;
  }

  .e-request-rejected {
    @extend .e-request-status;
    color: red;
  }

  .e-request-status-date {
    line-height: 15px;
    @extend %font12;
    color: $light_grey;
    font-weight: 400;
    text-align: center;
  }
}

/*-- report css--*/
.e-report {
  min-height: 140vh;

  p {
    font-weight: 400;
    @extend %font14;
    line-height: 20px;
    color: $dark_grey;
    padding-right: 4px;
  }

  h6 {
    font-weight: 600;
    @extend %font14;
    line-height: 20px;
    color: $dark_grey;
  }

  .e-report-save-btn {
    padding: 8px 16px;

    span {
      line-height: 24px;
    }
  }

  .e-send-report {
    padding: 8px 16px;

    span {
      line-height: 24px;
    }
  }
}

/* manage global class css */
.e-global-class-card {
  background-color: $accordion_bg !important;
  border-radius: 8px !important;
  color: #010101;
  padding: 12px 24px !important;
  min-width: 218px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // background-image: url("../assets/images/cta/delete-topic.svg");
  // background-repeat: no-repeat;
  // background-position: 95%;
}

/* -- modal select and button --*/
.modal .css-1s2u09g-control,
.modal .css-t3ipsp-control {
  border-radius: 8px !important;
  // height: 47px;

  &:hover {
    border-radius: 8px !important;
  }
}

.modal .e-disabled {
  opacity: 0.5;
  pointer-events: none;
  font-weight: 700;
  @extend %font14;
}

.e-edit-modal {
  width: 109px !important;
}

/*--- phone input css---*/
.PhoneInput {
  background: $white;
  border: 1px solid $input_boder;
  background-color: transparent;
  outline: none;
  width: 100%;
  font-weight: 400;
  color: $black;
  padding: 10px 15px;
  border-radius: 8px;

  &::focus {
    border: 1px solid $primary_color;
    border-radius: 8px;
  }
}

.PhoneInput--focus::focus {
  border: 1px solid $primary_color;
  border-radius: 8px;
}

.PhoneInput--focus {
  border: 1px solid $primary_color;
  outline: none !important;

  &::placeholder {
    font-weight: 500;
    @extend %font14;
    line-height: 18px;
    color: $black;
    opacity: 0.47;
  }
}

.PhoneInput {
  input {
    border: none !important;
    outline: none !important;

    &::placeholder {
      font-weight: 400;
      @extend %font14;
      line-height: 18px;
      color: $input_placeholder;
      letter-spacing: 0.25px;
    }

    &:focus {
      border: none !important;
      outline: none !important;
    }
  }
}

.ant-pagination-options-size-changer.ant-select {
  width: auto;
  display: none;
}

/*-------loader---*/
.e-btn-loader span {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}

.e-butn-loader {
  &:after {
    content: " .";
    font-family: "Epilogue";
    margin: 0;
    line-height: 100%;
    animation: dots 1s steps(1, end) infinite;
  }
}

@keyframes dots {
  0%,
  12.5% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    text-shadow: 0.5em 0;
  }

  75%,
  100% {
    text-shadow:
      0.5em 0,
      1em 0;
  }
}

.e-delete-btn {
  color: $red;
}

/*---------------Campaign detail----------*/
.e-campaign-detail-wrapper {
  background: $white;
  box-shadow: 8px 16px 56px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 24px;
  h6 {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: $light_grey;
  }

  h3 {
    @extend %h3;
    font-weight: 600;
    line-height: 30px;
    color: $grey;
  }
  .e-campaign-content-wrapper {
    background: #f5f6ff;
    border-radius: 16px;
    padding: 24px;
    height: 182px;
    h6 {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #6d6d6d;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #2d2d2d;
    }
    .e-toggle-span {
      font-weight: 400;
      font-size: 14px;
      color: #2d2d2d;
    }
    ul {
      padding-left: 24px;
      li {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #6d6d6d;
      }
    }
  }
}
.e-prefix-input {
  padding: 12px 15px 12px 24px !important;
}
.e-campaign-date {
  .ant-picker {
    border: 0.5px solid #dbdbdb !important;
    border-radius: 8px !important;
  }
  .ant-picker-range {
    height: 42px !important;
  }
  .ant-picker-input > input:placeholder-shown {
    color: $dark_grey;
    opacity: 0.27;
    font-weight: 400;
    line-height: 18px;
    font-size: 14px;
  }
}
.e-campaign-cancel {
  height: 40px;
  text-align: center;
  border: 1px solid #bebebe;
  padding: 8px 15px 8px 12px;
  border-radius: 8px;
  font-weight: 700;
  color: #6d6d6d;
  font-weight: 600;
}

/*user detail*/

.e-graph-data-wrapper1 {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 16px;
  padding: 12px 46px 18px;

  div {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #6d6d6d;
    .e-graph-class {
      width: 8px;
      height: 8px;
      padding: 2px;
      border-radius: 100px;
      position: relative;
      top: 4px;
      margin-right: 6px;
    }
    .e-target-class {
      background: #4f7097;
    }
    .e-performance-class {
      background: #106ee8;
    }
  }
}
.e-link {
  text-decoration-line: underline;
  cursor: pointer;
  line-height: 20px;
}

.apexcharts-text tspan {
  font-size: 9px !important;
}

.e-graph-data-wrapper2 {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 16px;
  padding: 12px 16px;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 135%;
    color: #6d6d6d;
  }

  &::-webkit-scrollbar {
    // border-radius: 10px;
    height: 10px !important;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #4f7097;
    padding: 20px;
    height: 10px !important;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    // height: 10px !important;
  }
}
.e-iJyeUs {
  &::-webkit-scrollbar {
    height: 4px !important;
    width: 0px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4dcf3 !important;
    border-radius: 0px;
  }
}
.e-exVHZH {
  // position: relative;
  .e-gHetzH {
    // position: sticky;
    padding-top: 1rem;
    // top: 0px;
    .e-jnWwQn {
      display: block;
      // android css
      .e-lGdcD {
        background-image: url("../assets/images/notification/android.svg");
        width: 450px;
        height: 780px;
        margin: 0px auto;
        line-height: 1px;
        .e-cdZahM {
          position: relative;
          top: 200px;
          margin: auto;
          width: 404px;
          cursor: pointer;
          user-select: none;
          .jlzOwm {
            display: grid;
            grid-template: "icon head collapse" / 24px auto 24px;
            gap: 12px 16px;
            font-family: "Open Sans", Roboto, Arial, Helvetica, sans-serif;
            padding: 20px 16px;
            border-radius: 28px;
            background-color: rgb(255, 255, 255);
            box-shadow:
              rgba(24, 39, 75, 0.12) 0px 6px 12px -6px,
              rgba(24, 39, 75, 0.08) 0px 8px 24px;

            .e-lbhYTY {
              grid-area: icon / icon / icon / icon;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              .e-hCDzWh {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;

                // background-color: rgb(0, 0, 0);
                border-radius: 12px;
                .e-eBxihg {
                  display: flex;
                  -webkit-box-pack: center;
                  justify-content: center;
                  -webkit-box-align: center;
                  align-items: center;
                  background-image: url("../assets/images/notification/favicon.webp");
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: 75% 75%;
                  width: 32px;
                  height: 32px;
                }
              }
            }
            .e-eTFcpK {
              grid-area: head / head / head / head;
              display: grid;
              gap: 4px 8px;
              width: 100%;
              height: 48px;
              font-size: 12px;
              grid-template:
                "title" auto
                "message" auto / 292px;
            }
            .e-gauFBm {
              display: flex;
              flex-flow: row;
              -webkit-box-align: center;
              align-items: center;
              column-gap: 1px;
              .e-ejVUYw {
                grid-area: message / message / message / message;
                color: rgb(69, 69, 69);
                font-size: 14px;
                line-height: 20px;
                font-weight: 400;
                letter-spacing: 0.1px;
                margin: 0px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                width: 100%;
                text-overflow: ellipsis;
                white-space: pre-wrap;
                word-break: break-word;
              }
              .e-khBlLl {
                grid-area: image / image / image / image;
                width: 48px;
                min-width: 48px;
                max-width: 48px;
                height: 48px;
                min-height: 48px;
                max-height: 48px;
                background-color: rgb(255, 255, 255);
                border-radius: 6px;
                object-fit: cover;
              }
            }
            .e-cBQgDv {
              min-width: max-content;
              white-space: nowrap;
            }
            .e-hFvvYD {
              grid-area: collapse / collapse / collapse / collapse;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              .jsykPp {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                background-image: url("../assets/images/notification/arrow-down.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 100% 100%;
                background-color: rgb(239, 239, 239);
                width: 24px;
                height: 24px;
                border-radius: 12px;
              }
              .cwKmnI {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                background-image: url("../assets/images/notification/arrow-up.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 100% 100%;
                background-color: rgb(239, 239, 239);
                width: 24px;
                height: 24px;
                border-radius: 12px;
              }
            }
            .e-kRktcz {
              grid-area: title / title / title / title;
              color: rgb(27, 27, 27);
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              letter-spacing: 0.1px;
              margin: 0px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: pre-wrap;
              max-width: 332px;
              word-break: break-word;
            }
            .djPDJK {
              grid-area: message / message / message / message;
              color: rgb(69, 69, 69);
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              letter-spacing: 0.1px;
              margin: 0px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              width: 100%;
              text-overflow: ellipsis;
              white-space: pre-wrap;
              word-break: break-word;
            }

            .e-bfKFlL {
              grid-area: image / image / image / image;
              display: flex;
              width: 100%;
              max-height: 160px;
              object-fit: cover;
              background-color: rgb(255, 255, 255);
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 16px;
            }
          }
          .eDMUfQ {
            display: grid;
            display: grid;
            grid-template-columns: 24px auto 24px;
            grid-template-areas:
              "icon head collapse"
              "blank title title"
              "blank message message"
              "blank image image";
            grid-template-rows: auto;
            font-family: "Open Sans", Roboto, Arial, Helvetica, sans-serif;
            background-color: rgb(255, 255, 255);
            box-shadow:
              rgba(24, 39, 75, 0.12) 0px 6px 12px -6px,
              rgba(24, 39, 75, 0.08) 0px 8px 24px -4px;
            gap: 12px 16px;
            padding: 20px 16px;
            border-radius: 28px;

            .e-lbhYTY {
              grid-area: icon / icon / icon / icon;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              .e-hCDzWh {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                // width: 24px;
                // height: 24px;
                // background-color: rgb(0, 0, 0);
                border-radius: 12px;
                .e-eBxihg {
                  display: flex;
                  -webkit-box-pack: center;
                  justify-content: center;
                  -webkit-box-align: center;
                  align-items: center;
                  background-size: cover;
                  background-image: url("../assets/images/notification/favicon.webp");
                  background-repeat: no-repeat;
                  background-position: 75% 75%;
                  width: 32px;
                  height: 32px;
                }
              }
            }
            .e-eTFcpK {
              grid-area: head / head / head / head;
              display: grid;
              gap: 4px 8px;
              width: 100%;
              height: auto;
              font-size: 12px;
              grid-template: auto / auto;
            }
            .e-gauFBm {
              display: flex;
              flex-flow: row;
              -webkit-box-align: center;
              align-items: center;
              column-gap: 1px;

              .e-khBlLl {
                grid-area: image / image / image / image;
                width: 48px;
                min-width: 48px;
                max-width: 48px;
                height: 48px;
                min-height: 48px;
                max-height: 48px;
                background-color: rgb(255, 255, 255);
                border-radius: 6px;
                object-fit: cover;
              }
            }
            .e-cBQgDv {
              min-width: max-content;
              white-space: nowrap;
            }
            .e-hFvvYD {
              grid-area: collapse / collapse / collapse / collapse;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              .jsykPp {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                background-image: url("../assets/images/notification/arrow-down.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 100% 100%;
                background-color: rgb(239, 239, 239);
                width: 24px;
                height: 24px;
                border-radius: 12px;
              }
              .cwKmnI {
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                background-image: url("../assets/images/notification/arrow-up.svg");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: 100% 100%;
                background-color: rgb(239, 239, 239);
                width: 24px;
                height: 24px;
                border-radius: 12px;
              }
            }
            .e-kRktcz {
              grid-area: title / title / title / title;
              color: rgb(27, 27, 27);
              font-size: 14px;
              line-height: 20px;
              font-weight: 600;
              letter-spacing: 0.1px;
              margin: 0px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: pre-wrap;
              max-width: 332px;
              word-break: break-word;
            }
            .djPDJK {
              grid-area: message / message / message / message;
              color: rgb(69, 69, 69);
              font-size: 14px;
              line-height: 20px;
              font-weight: 400;
              letter-spacing: 0.1px;
              margin: 0px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              width: 100%;
              text-overflow: ellipsis;
              white-space: pre-wrap;
              word-break: break-word;
            }

            .e-bfKFlL {
              grid-area: image / image / image / image;
              display: flex;
              width: 100%;
              max-height: 160px;
              object-fit: cover;
              background-color: rgb(255, 255, 255);
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              border-radius: 16px;
            }
          }
          .djPDJN {
            grid-area: message / message / message / message;
            color: rgb(69, 69, 69);
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            letter-spacing: 0.1px;
            margin: 0px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
            width: 100%;
            text-overflow: ellipsis;
            white-space: pre-wrap;
            word-break: break-word;
          }
        }
      }

      // ioS css
      .e-jUtGcO {
        width: 424px;
        min-height: 780px;
        // min-height: 1060px;
        background-image: url("../assets/images/notification/iphone.svg");
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0px auto;
        overflow: hidden;
        font-family: -apple-system, BlinkMacSystemFont, sans-serif;

        .e-eCDnqH {
          position: relative;
          margin-top: 250px;
          margin-bottom: 0px;
          left: 32px;
          cursor: pointer;
          width: 360px;
          .e-keNpes {
            border-radius: 20px;
            box-shadow: rgb(220, 220, 220) 0px 0px 8px 0px;
            max-width: 360px;
            background-color: white;
            overflow-y: hidden;
            .e-iJyeUs {
              overflow-x: scroll;
            }
            .e-feINqK {
              display: grid;

              grid-template-columns: 36px auto 28px;
              grid-template-rows: auto auto auto;
              grid-template-areas: "appIcon titlesAndBody timeAndThumbnail";
              gap: 0px 10px;
              padding: 12px;
              .e-hrzOVh {
                grid-area: appIcon / appIcon / appIcon / appIcon;
                display: flex;
                -webkit-box-pack: center;
                justify-content: center;
                -webkit-box-align: center;
                align-items: center;
                .e-hcJkSI {
                  width: 32px;
                  height: 32px;
                  // background-color: rgb(0, 0, 0);
                  display: flex;
                  -webkit-box-pack: center;
                  justify-content: center;
                  -webkit-box-align: center;
                  align-items: center;
                  background-size: cover;
                  background-image: url("../assets/images/notification/favicon.webp");
                  // .e-jTUlZf {
                  //   width: 32px;
                  //   height: 32px;
                  //   margin-top: 3px;
                  // }
                }
              }
              .e-lcoedz {
                grid-area: titlesAndBody / titlesAndBody / titlesAndBody / titlesAndBody;
                display: flex;
                flex-direction: column;
                -webkit-box-pack: center;
                justify-content: center;
                .e-lmHNfd {
                  font-size: 13px;
                  letter-spacing: -0.09px;
                  color: rgb(27, 27, 27);
                  font-size: 14px;
                  line-height: 20px;
                  font-weight: 600;
                  margin: 0px;
                  width: 250px;
                  display: block;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .e-fpcwso {
                  text-overflow: ellipsis;
                  max-height: 200px;
                  box-sizing: content-box;
                  .e-lmHNfd {
                    font-size: 13px;
                    letter-spacing: -0.09px;
                    color: rgb(0, 0, 0);
                    user-select: none;
                    font-weight: 500;
                    line-height: 16px;
                    margin: 0px;
                    width: 250px;
                    display: block;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                  .e-bdxVC {
                    font-size: 13px;
                    font-weight: 300;
                    letter-spacing: -0.09px;
                    line-height: 15.5px;
                    color: rgb(0, 0, 0);
                    user-select: none;
                    margin: 0px;
                    white-space: pre-wrap;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    max-height: 75px;
                    -webkit-line-clamp: 4;
                  }
                }
              }
              .e-eMWWsl {
                grid-area: timeAndThumbnail / timeAndThumbnail / timeAndThumbnail / timeAndThumbnail;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 10px;
                .e-fYIOIr {
                  text-align: right;
                  font-size: 11px;
                  line-height: 0.75;
                }
              }
            }
            .e-cmYsgE {
              display: block;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.e-customer-input {
  border: 1px solid #6d6d6d;
  border-radius: 4px;
}

.e-show-search-dropdown {
  top: 80px;
  width: 100%;
  background-color: white;
  height: 200px;
  border: 1px solid $light_grey;
  padding: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99;
  border-radius: 8px;
  p {
    cursor: pointer;
    &:hover {
      color: $primary_color;
    }
  }
}
.e-show-search-dropdown::-webkit-scrollbar {
  height: 0px !important;
  width: 8px;
}

.e-show-search-dropdown::-webkit-scrollbar-thumb {
  background: #edf3ff;
}

.e-cart-search-dropdown {
  top: 100px;
  width: 100%;
  background-color: white;
  height: 200px;
  box-shadow:
    0px 4px 6px -2px #10182808,
    0px 12px 16px -4px #10182814;
  padding: 16px;
  border: 1px solid #f2f4f7;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 99;
  border-radius: 8px;
  p {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    color: #2d2d2d;
    &:hover {
      color: $primary_color;
    }
  }
}
.e-cart-search-dropdown::-webkit-scrollbar {
  height: 0px !important;
  width: 8px;
}

.e-cart-search-dropdown::-webkit-scrollbar-thumb {
  background: #edf3ff;
}

.e-profile-icon {
  background: #d2d5ea;
  min-width: 120px;
  height: 120px;
  border-radius: 100%;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  color: $primary_color;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e-profile-name {
  color: $grey;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
}

.e-info {
  background: #f5f6ff;
  h6 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: $light_grey;
    margin-bottom: 12px;
  }
  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: $black;
    margin-bottom: 0px;
  }
}

.opacity-0 {
  opacity: 0;
}

.e-create-cart {
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  input {
    font-size: 14px !important;
    padding: 8px;
    border: 1px solid #bebebe !important;
    color: #2d2d2d !important;
  }
  .e-right-border {
    border-right: 1px solid #bebebe !important;
  }
  .e-suffix {
    right: 12px !important;
    top: 6px !important;
  }

  .w-100px {
    width: 100px !important;
  }
  .w-120px {
    width: 120px !important;
  }

  .w-70px {
    width: 70px !important;
  }
}

.e-font-14 {
  font-size: 14px;
}

.e-poppins-regular {
  font-family: "Poppins";
  font-weight: 400;
}

.color-grey {
  color: $grey;
}

.e-sip-date {
  padding: 8px 8px 6px 8px;
  border-radius: 8px;
  border: 1px solid #bebebe;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #2d2d2d;
  cursor: pointer;
  width: max-content;
}

.e-sip-date-disabled {
  background-color: #f6f6f6;
  cursor: no-drop;
  pointer-events: none;
  width: max-content;
}

.e-cart-basket-inputs {
  background: #f5f6ff;
  padding: 18px 24px;
}

.e-min {
  color: #077559;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.09000000357627869px;
  position: absolute;
}

.e-add-new {
  color: $primary_color;
  font-size: 14px;
  font-weight: 600;
  width: max-content;
}
