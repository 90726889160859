@import "../config.scss";

.e_sip_modal {
    display: flex !important;
    justify-content: center;
    align-items: center;
    .e_dialog {
        max-width: 22.5rem !important;

        @media (max-width: 576px) {
            max-width: 20rem; // Full width for small screens
        }
    }

    .e_content {
        border: none;
        padding-bottom: 22px;
        border-radius: 16px;
        h6 {
            font-size: 14px;
            color: $dark_grey;
            font-family: "Poppins";
            line-height: 20px;
            font-weight: 600;
        }
        p {
            margin-bottom: 12px;
            color: $dark_grey;
            font-family: "Poppins";
            line-height: 24px;
            font-weight: 600;
        }
        .e_active_date {
            background-color: $primary_color;
            border-radius: 8px;
            color: $white;
        }

        .e_sip_date {
            width: 40px;
            height: 40px;
            font-family: "Poppins";
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            cursor: pointer;
        }
    }
}
